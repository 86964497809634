import axios from "axios";

if(process.env.NODE_ENV === 'development'){
  window.$urlBack = 'http://sirmovil.test';
}else{
  window.$urlBack = 'https://sirmobilebackqa.azurewebsites.net';
}
window.$location = 'ECU';
axios.defaults.baseURL = window.$urlBack;

axios.interceptors.request.use( (config) => {
    const token_seguridad = sessionStorage.getItem('token');
    if (token_seguridad) {
      config.headers.Authorization = 'Bearer ' + token_seguridad;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      sessionStorage.clear();
      window.parent.location = '/';
      let data = {
        response: false,
        data: [],
        headers: [],
        alert: '',
        messages: ['Acceo no autorizado']
      }
      return {data: data};
    } else if (error.response.status === 500){
      let data = {
        response: false,
        data: [],
        headers: [],
        alert: '',
        messages: ['Ocurrio un error de comunicacion con el servidor comuniquese con soporte']
      }
      return {data: data};
    }
});

const requestGenerico = {
  get: (url) => axios.get(url),
  post: (url, body) => axios.post(url, body),
  put: (url, body) => axios.put(url, body),
  delete: (url) => axios.delete(url),
};

export default requestGenerico;
